exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brands-brand-slug-js": () => import("./../../../src/pages/brands/{Brand.slug}.js" /* webpackChunkName: "component---src-pages-brands-brand-slug-js" */),
  "component---src-pages-brands-brands-js": () => import("./../../../src/pages/brands/brands.js" /* webpackChunkName: "component---src-pages-brands-brands-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-slug-js": () => import("./../../../src/pages/{Page.slug}.js" /* webpackChunkName: "component---src-pages-page-slug-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

